import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Layout } from "../components/common"
import { Button } from "react-bootstrap"
import { Link } from "gatsby"
import ContactUsBtn from "../components/common/ContactUsBtn";

let videoBG = require('../images/page-images/bridge.jpg')
let houseImage = require('../images/page-images/aerial-real-estate.jpg');
let commercialMarketing = require('../images/page-images/commercial-real-estate.jpg');
let fullRealEstate = require('../images/page-images/416-east-oak.jpg');


class BridgeAndInfrastructure extends Component {
    render() {
        return (
            <Layout>

                <div className="container">
                    <h1>
                        Fast, Reliable, Quality
                    </h1>
                    <div className="row">
                        <div className="col-md-6">
                            <img className={'mbl'} src={houseImage} alt=""/>
                        </div>
                        <div className="col-md-6">
                            <h3>Residential Real Estate Aerial Photography</h3>
                            <p>
                                Drone aerial real estate photography and video can present new angles of a property to prospective buyers. The goal is to help make the property as attractive to prospective buyers as possible. Drone aerial photography and video are particularly useful for highlighting many larger properties including those with large lot sizes and/or are located near the water. Aerial photos and video can also help give prospective buyers a better idea of the surrounding community environment.
                            </p>

                            <ContactUsBtn/>
                        </div>
                    </div>

                    <hr/>

                    <div className="row">
                        <div className="col-md-6">
                            <h3>Full Real Estate Phtotography Service</h3>
                            <p>
                                We offer comprehensive, professional-quality Aerial Photography & Video Services appropriate for representing high-end real estate.
                            </p>
                            <ul>
                                    <li>Exterior & Aerial Photography & Video</li>
                                    <li>Aerial 360-degree Interactive Panoramas (example to the left)</li>
                                    <li>Professional Interior Photography</li>
                                    <li>Interior Virtual Walkthrough Video or Interactive 360-degree Photos</li>
                                </ul>

                            <div className="mbl">
                                <ContactUsBtn/>
                            </div>

                        </div>
                        <div className="col-md-6">
                            <img className={'mbl'} src={fullRealEstate} alt=""/>
                        </div>
                    </div>

                    <hr/>

                    <div className="row">
                        <div className="col-md-6">
                            <img className={'mbl'} src={commercialMarketing} alt=""/>
                        </div>
                        <div className="col-md-6">
                            <h3>Commercial Marketing</h3>
                            <p>
                                Show off your office building or multi-unit residential condo or apartment building with aerial photography and video. Drone footage allows you to show potential buyers or renters a complete overview of the property and the surrounding area. Aerial real estate photography and video can help you highlight the landscapes, short drive to schools, or what establishments are nearby.  We can also provide aerial photos and video showing views at various elevations for buildings that have not even been completed yet.
                            </p>
                            <ContactUsBtn/>
                        </div>
                    </div>

                </div>
            </Layout>
        )
    }
}

BridgeAndInfrastructure.propTypes = {}

export default BridgeAndInfrastructure
